const members = [
  {
    name: "Brent Billey",
    firstName: "Brent",
    lastName: "Billey",
    position: "Chief Executive Officer",
    bio: "Mr. Brent Billey is the Chief Executive Officer of Kadestone Capital. He is accountable for the strategic direction and overall management of the company. His emphasis is on building shareholder value through the identification and development of real estate assets. Brent's career has spanned over 28 years with BC Hydro, most recently responsible for complex, large scale project negotiations with Indigenous groups in British Columbia. Brent has over $700M worth of construction projects under his belt. He has also managed partnerships in the development of over $1 billion in capital and customer driven projects while at BC Hydro. Prior to his role with Indigenous Relations, Brent managed multiple sectors in the Key Account Management group. These varied from healthcare and education, to large industrial and national accounts. Brent started his career in marketing with the Power Smart programs. He is recognized in the real estate industry for his ability to bring partners together to successfully deliver large scale projects.",
    isManagement: true,
    isBoardOfDirectors: true,
  },
  {
    name: "Kevin Hoffman",
    firstName: "Kevin",
    lastName: "Hoffman",
    position: "Chief Development Officer",
    bio: "Mr. Kevin Hoffman is the Chief Development Officer of Kadestone Capital. Kevin is a dynamic senior executive with a wealth of experience in developing large master-planned communities within the real estate sector and will be responsible for pursuing, obtaining and executing on development projects for Kadestone. Kevin joins Kadestone after a successful tenure as Chief Executive Officer of Vanprop Investments, where he spearheaded the redevelopment of a significant 50-acre mall site. Previously, as President of Aquilini Development and Construction, Kevin managed an impressive portfolio that included a variety of residential, commercial, and mixed-use projects, often collaborating with First Nations to foster inclusive communities. His career spans over two decades, with prior roles at Concord Pacific Group and as a founding partner of Bluestone Construction. Armed with a Master's in Business Administration and a strong dedication to community engagement, Kevin is well-regarded for his innovative strategies and exceptional leadership in property development.",
    isManagement: true,
    isBoardOfDirectors: false,
  },
  {
    name: "David Negus",
    firstName: "David",
    lastName: "Negus",
    position: "Chief Financial Officer",
    bio: "Mr. David Negus is the Chief Financial Officer of Kadestone Capital and is accountable for the financial and administrative operations of the Company. David is an accomplished Chief Financial Officer with deep public markets corporate finance expertise. He has extensive experience in leading public companies and pre-IPO organizations. David most recently served as the CFO of Sunniva, a CSE listed cannabis Company. His responsibilities as Sunniva included financing, strategic planning and the build out of the support functions of the business. Previously, David was CFO at Luvo, a high growth food Company. At Luvo, he was responsible for financing, mergers and acquisitions and strategic planning. Prior to his role at Luvo, David was Vice President, Corporate Controller at Lululemon. In his role at Lululemon, David led the finance team through their initial public offering and was responsible for their global financial reporting, accounting, tax, and treasury functions. As part of the Lululemon leadership team, he played an integral role in the development and build out of a finance team that supported the business from a private company to a multi-billion dollar international organization.",
    isManagement: true,
    isBoardOfDirectors: false,
  },
  {
    name: "Dr. Anthony Holler",
    firstName: "Anthony",
    lastName: "Holler",
    bio: "Dr. Anthony (Tony) Holler is Chairman of the Board of Directors for Sunniva Inc. Previously, Dr. Holler was Chairman of the Board of Directors of Perimeter Medical Imaging AI, Inc. and CRH Medical. Prior to joining CRH, Dr. Holler was one of the founders of ID Biomedical Corporation. He held a number of executive positions with ID Biomedical, including Chief Executive Officer until the company was acquired by GlaxoSmithKline in December 2005 for $1.7 billion. Dr. Holler was also Chairman of Corriente Resources Inc., which sold for approximately $700 million to CRCC-Tongguan Investment Co. in 2010. Before his involvement in public markets, Dr. Holler served as an Emergency Physician at University Hospital at the University of British Columbia. He holds a Bachelor of Science Degree and a Medical Degree from the University of British Columbia. His expertise includes strategic planning, mergers and acquisitions, and financing, with a singular focus on increasing shareholder value.",
    isManagement: false,
    isBoardOfDirectors: true,
  },
  {
    name: "David Negrin",
    firstName: "David",
    lastName: "Negrin",
    bio: "Mr. David Negrin is currently CEO of Attollo Management Inc. and formally was CEO of the MST Development Corporation managing billions worth of real estate development with First Nations groups. David has over 30 years of leadership in the development and construction industry throughout Canada   Previously he was the President of Aquilini Development and Construction Ltd, where among other projects, he oversaw the construction of three towers around Rogers Arena, Prior to joining Aquilini Development and Construction Ltd, David was the Senior Vice President of Concord Pacific Group where he oversaw over 8 million SF from inception to completion As an industry leader in restorative development, David has also served as both a Director and President of the Urban Development Institute – Pacific Region.",
    isManagement: false,
    isBoardOfDirectors: true,
  },
  {
    name: "Kent Sillars",
    firstName: "Kent",
    lastName: "Sillars",
    bio: "Mr. Kent Sillars is the Founder and President of the Vesta Group of Companies and has been involved in the development and construction of residential real estate communities in British Columbia and Alberta since 1989. The Vesta Group includes Vesta Properties Ltd., a real estate developer in British Columbia and Alberta and Tier Construction Ltd, a civil servicing contractor in Greater Vancouver. Vesta Properties Ltd has built over 6500 homes to date and has developed several large, mixed use communities in British Columbia and Alberta involving residential, commercial and office properties. Mr. Sillars completed his Bachelor of Business Administration (BBA) in Accounting and Finance at Simon Fraser University and also holds the certification CPA, CA from the Canadian Institute of Chartered Accountants.",
    isManagement: false,
    isBoardOfDirectors: true,
  },
  {
    name: "Norm Mayr",
    firstName: "Norm",
    lastName: "Mayr",
    bio: "Mr. Norm Mayr is a retired (October 2016) Audit Partner having spent 38 years in public practice with KPMG. He was the Risk Management and Business Unit Professional Practice Partner for the Greater Vancouver Area practice of KPMG for the most recent 18 years of his career. In this role, Norm was responsible for managing risk in the audit practice, and regularly consulted with engagement teams dealing with complex financial reporting, accounting, audit and securities issues in their clients. During his career, Norm has had extensive experience in the mining, forestry, technology, real estate, retail and industrial markets sectors. He has served as lead engagement partner and engagement quality review partner on many of the KPMG's largest clients in these industries, including multinational reporting issuers. Norm has lectured extensively on financial reporting matters. He was a founding member of the CICA Accounting Standards Board, and a member of the Canadian Advisory Group to the International Accounting Standards Committee. He recently completed a 10-year term  as Chair of the Investigation Committee for the Chartered Professional Accountants of British Columbia, a committee charged with the responsibility of investigating alleged misconduct by its members.",
    isManagement: false,
    isBoardOfDirectors: true,
  },
];

const ManagementData = members.filter((x) => x.isManagement);

const BoardData = members
  .filter((x) => x.isBoardOfDirectors)
  .sort((a, b) =>
    a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase())
  );

export default {
  ManagementData,
  BoardData,
  members,
};
